<template>
  <section>
    <TitleBar>
      Templates de communication
      <template #toolbar-content>
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent :societe="societe"></StrapiEditoComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(objet)="{ item }">
          <b-link
            :to="{ name: 'com-assistant', params: { idTemplate: item.id } }"
          >
            {{ item.objet }}
          </b-link>
        </template>
        <template #cell(creeLe)="{ item }">
          {{ isoSqlInstantToFrenchInstant(item.creeLe) }}
        </template>
        <template #cell(modifieLe)="{ item }">
          {{ isoSqlInstantToFrenchInstant(item.modifieLe) }}
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import CommunicationService from "../../services/communication.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "TemplatesView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.COM_TEMPLATES),
      error: null,
      templates: [],
      page: null,
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    societe() {
      return this.exploitationCourante?.codeSociete;
    },
  },
  created,
  async mounted() {
    await this.loadTemplates();
    await this.load();
  },
  methods: {
    ...tableMethods,
    isoSqlInstantToFrenchInstant(instant) {
      return UtilsService.isoSqlInstantToFrenchInstant(instant);
    },
    async loadTemplates() {
      this.error = null;
      try {
        let { data } = await CommunicationService.searchTemplatesMail(
          this.societe,
          { genericFilters: { operation: "$and", operands: [] } }
        );
        this.templates = data.content;
      } catch (error) {
        this.templates = [];
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let { data } = await CommunicationService.searchTemplates(
          this.societe,
          this.getSearchPayload()
        );
        data.content.forEach((c) => {
          c.template = this.templates.find((t) => t.id === c.idTemplate);
        });
        this.page = data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
  },
};
</script>
